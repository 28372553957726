<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款对象">
              <a-select
                v-model="queryParam['customer_id']"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
                placeholder="选择付款对象"
              >
                <a-select-option v-for="o in payTargetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="状态">
              <a-select v-model="queryParam['fee_status']" showSearch allowClear style="width: 100%" placeholder="选择费用状态">
                <a-select-option v-for="o in GLOBAL.finPayFeeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="queryParam.source"
                @change="changeSource"
              >
                <a-select-option :key="4">仓储行政</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleApplyBizAudit()">商务审核</a-button>
      <a-button type="primary" icon="download" @click="exportStatementSheet">导出对账单</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal,
        clear: true
      }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange, onSelect: this.selectItem, onSelectAll: this.selectAllItem }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="feeItem" slot-scope="fee">
        <div v-for="item in fee" :key="item.id">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.amount }}</span>
        </div>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="printPayBillSheet(record)">付款单</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="applyFormVisible"
      :confirmLoading="confirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="applyFormVisible = false"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-divider orientation="left">基础信息</a-divider>
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="付款方式">
              <a-select v-model="form.pay_method">
                <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="是否预付" prop="is_advance">
              <a-select v-model="form.is_advance">
                <a-select-option :value="item.value" v-for="item in isActiveOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="附件数" prop="appendix_num">
              <a-input suffix="张" v-model="form.appendix_num"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="发票号码" prop="invoice_number">
          <a-select
            showSearch
            mode="multiple"
            ref="invoice_number"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择发票号码"
            style="width: 100%"
            v-model="form.invoice_num"
          >
            <a-select-option v-for="op in invoiceRegOps" :key="op.id"
            >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}
              {{ op.invoice_type === 2 ? '/凭证类型:缴款单':'' }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合同号" prop="contract_list">
          <a-select
            showSearch
            mode="multiple"
            ref="contract_list"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择合同号"
            style="width: 100%"
            v-model="form.contract_list"
          >
            <a-select-option v-for="op in leaseInfoList" :key="op.id">
              合同号:{{ op.contract_no }}
              <span v-if="op.is_no_term===0 && !op.contract_termination_date">/截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
              <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
              / {{ op.customer_info.customer_cn_name }}
              <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          style="padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-row>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-row v-for="item in feeOrderList" :key="item.id">
              <a-alert :message="`业务编号:${item.orderId}`" type="info" show-icon style="margin-bottom:10px;" />
              <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
                <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.amount }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-row>
        <a-form-model-item label="申请备注" prop="apply_remark">
          <a-textarea v-model="form.apply_remark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 付款通知单 -->
    <a-modal
      :visible="printPayBillVisible"
      :confirmLoading="printPayBillLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillVisible = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillOrderList"
          :orderData="printPayBillOrderData">
        </fms-pay-bill>
      </div>

      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayBillVisible = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment'
import { filterOption } from '@/utils/util'
import { getSupplier, getCommonNoPage, downloadExcel, getLeaseInfoListByDepartment } from '@/api/common'
import { getWmsFinancePage, wmsFinancePayFunc, exportPayStatementSheet } from '@/api/wms'
import FmsPayBill from '@/views/fms/bill/FmsPayBill'
export default {
  components: {
    STable,
    FmsPayBill
  },
  data() {
    return {
      queryParam: {
        source: 4
      },
      timeType: 'box_time',
      defaultDate: [moment().startOf('month'), moment().endOf('month')],
      payTargetOps: [],
      feeOrderList: [],
      invoiceRegOps: [],
      totalAmount: 0,
      checkedList: [],
      indeterminate: false,
      checkAll: true,
      showTotal: '选择条数:0 合计:0',
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 80,
          align: 'center'
        },
        {
          title: '作业编号',
          dataIndex: 'operate_num'
        },
        {
          title: '付款对象',
          dataIndex: 'customer'
        },
        {
          title: '费用',
          dataIndex: 'fee_list',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY-MM-DD')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getWmsFinancePage('pay', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      isActiveOps: [
        {
          value: 1, name: '非预付'
        },
        {
          value: 2, name: '预付'
        }
      ],
      confirmLoading: false,
      applyFormVisible: false,
      form: {
        pay_method: undefined,
        is_advance: undefined,
        appendix_num: null,
        invoice_num: [],
        apply_remark: '',
        contract_list: []
      },
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }]
      },
      // 打印付款单
      printPayBillVisible: false,
      printPayBillLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillOrderList: [],
      printPayBillOrderData: {},
      leaseInfoList: []
    }
  },
  created() {
    getSupplier({ department: 1 }).then(res => {
      this.payTargetOps = res
    })
  },
  watch: {
    selectedRowKeys: function() {
      let total = 0
      if (this.selectedRows && this.selectedRows.length > 0) {
        for (const item of this.selectedRows) {
          total += item.total
        }
      }
      this.showTotal = `选择条数:${this.selectedRowKeys.length} 合计:${total}`
    }
  },
  methods: {
    moment,
    // 打印付款单
    printPayBillSheet(record) {
      this.printPayBillVisible = true
      // 基础数据
      this.printPayBillOrderData = record
      this.printPayBillOrderData['department'] = 1
      this.printPayBillOrderData[`currency_name`] = `CNY`
      // 费用列表
      const feeList = []
      record.fee_list.forEach(fee => {
        feeList.push({
          order_num: record.operate_num,
          fee_name: fee.fee_name,
          amount: fee.amount
        })
      })
      this.printPayBillOrderList = feeList
    },
    filterOption,
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.id === item.id) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    changeSource(value) {
      this.$refs.table.refresh(true)
    },
    handleVerifyOrAudit(operate) {
      // 业务审核
      const data = []
      this.selectedRows.forEach(v => {
        v.fee_list && v.fee_list.forEach(fee => {
          switch (operate) {
            case 'audit':
              if (fee.fee_status === 1) {
                data.push(fee.id)
              }
              break
            case 'undo_audit':
              if (fee.fee_status === 2) {
                data.push(fee.id)
              }
              break
          }
        })
      })
      if (data.length) {
        wmsFinancePayFunc(operate, data).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.$refs.table.refresh(false)
          this.$refs.table.clearSelected()
          this.selectedRows = []
        })
      } else {
        this.$message.warning('请选择' + (operate === 'undo_audit' ? '已审核' : '未审核') + '的数据')
      }
    },
    handleApplyBizAudit() {
      this.clearForm()
      // 提交商务审核
      // let flag = true
      this.checkedList = []
      const feeMaps = []
      let flag = true
      let total = 0
      this.selectedRows.forEach(v => {
        v.fee_list && v.fee_list.map(e => {
          console.log(e.fee_status)
          if (e.fee_status !== 2 && e.fee_status !== 4) {
            flag = false
          }
        })
      })
      if (flag) {
        this.selectedRows.forEach(v => {
          const orderFee = {
            orderId: v.operate_num,
            feeList: v.fee_list && v.fee_list.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
                total += e.amount ? e.amount : 0
                this.checkedList.push(e.id)
                return {
                  id: e.id,
                  feeName: e.fee_name,
                  amount: e.amount
                }
              })
          }
          feeMaps.push(orderFee)
        })
        this.totalAmount = total
        this.feeOrderList = feeMaps
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        const idList = []
        this.selectedRows.forEach(item => {
          idList.push(item.customer_id)
        })
        getLeaseInfoListByDepartment({ 'department': 1, 'customer_id_list': idList }).then(v => {
          this.leaseInfoList = v
        })
        this.applyFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          wmsFinancePayFunc('apply_finance_audit', {
            pay_method: this.form.pay_method,
            apply_remark: this.form.apply_remark,
            appendix_num: this.form.appendix_num,
            id_list: this.checkedList,
            invoice_num: this.form.invoice_num,
            is_advance: this.form.is_advance,
            contract_list: this.form.contract_list
          })
          .then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.confirmLoading = false
            this.applyFormVisible = false
            this.$refs.table.clearSelected()
            this.selectedRows = []
            this.$refs.table.refresh(false)
          }).finally(_ => {
            this.confirmLoading = false
          })
        }
      })
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = 0
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              this.totalAmount += item.feeList[j].amount
            }
          }
        }
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = 0
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            this.totalAmount += e.amount
            this.checkedList.push(e.id)
          }
        })
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['create_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['create_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['create_start_date'] = null
        this.queryParam['create_end_date'] = null
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    clearForm() {
      this.form = {
        pay_method: undefined,
        is_advance: undefined,
        invoice_num: [],
        apply_remark: ''
      }
    },
    // 导出对账单
    exportStatementSheet() {
      exportPayStatementSheet(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    }
  }
}
</script>
